import { useEffect, useRef, useState, useMemo } from 'react';
import { Button, TextField, Checkbox, FontIcon } from 'react-md';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Fuse from 'fuse.js';
import { FUSE_OPTIONS, idFormatter, truncate, orderByKey } from '../../utils';
import './FilterChip.scss';

export default function FilterChip({ onApply, onRemove, filterKey, label, direction, labelKey, initialData, setInitialData }) {
  const [expanded, setExpanded] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const element = useRef();

  useEffect(() => {
    document.addEventListener('mousedown', handleClick, false);

    // shameful hack - style container to prevent break in flow
    const containerElement = element.current.parentElement;

    setTimeout(() => {
      const width = containerElement.clientWidth;
      containerElement.style.minWidth = width + 0 + 'px';
    }, 2000);

    return () => {
      document.removeEventListener('mousedown', handleClick, false);
    };
  });

  function handleClick(e) {
    if (element.current.contains(e.target)) {
      return;
    }

    if (e.target.id === 'clear-filter-btn') {
      selectAll();
    }

    setExpanded(false);
    setSearchTerm('');
  }

  function countChecked() {
    const checkedOptions = _.filter(initialData, { isChecked: true }).length;

    if (checkedOptions === initialData.length) {
      return 'all';
    } else {
      return checkedOptions;
    }
  }

  function selectAll() {
    setInitialData(initialData?.map(({ name, id }) => ({ name, id, isChecked: true })));
  }

  function deselectAll() {
    setInitialData(initialData?.map(({ name, id }) => ({ name, id, isChecked: false })));
  }

  function isRemovable() {
    return _.filter(initialData, { isChecked: true }).length < initialData?.length;
  }

  function handleSetExpand(value, e) {
    e.stopPropagation();

    setExpanded(value);

    if (value) {
      setSearchTerm('');
    }
  }

  function handleApply(value, e) {
    e.stopPropagation();
    setExpanded(value);

    const selected = _.map(_.filter(initialData, { isChecked: true }), (item) => item.id);
    let applied = selected.length ? selected : initialData.map(item => item.id);

    onApply(applied, filterKey);
  }

  function handleRemove(e) {
    e.stopPropagation();

    const selected = initialData.map(({ name, id }) => ({ name, id, isChecked: true }));
    setInitialData(selected);

    const applied = initialData.map(item => item.id);
    onApply(applied, filterKey);

    if (onRemove) {
      onRemove(applied, filterKey);
    }
  }

  function handleOnChangeCheckbox(v, item) {
    const index = _.findIndex(initialData, obj => obj.id === item.id);
    let newList = [...initialData];
    newList[index] = {
      ...newList[index],
      isChecked: v
    };

    setInitialData(newList);
  }

  function handleOnChangeSelectAll(checked) {
    return checked ? selectAll() : deselectAll();
  }

  function isAllSelected() {
    const containsCheckedFalse = _.some(initialData, ['isChecked', false]);
    return !containsCheckedFalse;
  }

  const filteredList = useMemo(() => {
    if (!searchTerm) {
      return orderByKey(initialData, 'name', 'asc');
    }

    const fuse = new Fuse(initialData, FUSE_OPTIONS);
    const results = fuse.search(searchTerm);
    const filteredWithCheckState = results.map(result =>
      _.find(initialData, ['id', result.item.id])
    );

    return filteredWithCheckState;
  }, [searchTerm, initialData]);

  return (
    <>
      {!expanded &&
        <div ref={element} id={`${idFormatter(label)}-filter`}>
          <div className={`filter-chip ${isRemovable() ? 'removable' : ''}`} onClick={(e) => handleSetExpand(true, e)}>
            <div className="filter-chip-header">
              <span className="chip-label">
                {`${label} (${countChecked()})`}
              </span>
              <>
                {isRemovable() &&
                  <Button
                    id={`${idFormatter(label)}-filter-cancel`}
                    className="chip-remove-button"
                    onClick={(e) => handleRemove(e)}
                    buttonType="icon"
                    aria-label="remove"
                  >
                    <FontIcon>cancel</FontIcon>
                  </Button>
                }
                {!expanded && !isRemovable() &&
                  <FontIcon
                    id={`${idFormatter(label)}-filter-drop-down`}
                    className="chip-icon"
                  >
                    arrow_drop_down
                  </FontIcon>
                }
              </>
            </div>
          </div>
        </div>
      }
      {expanded &&
        <div ref={element} id={`${idFormatter(label)}-filter`}>
          <div className={`filter-chip expanded expand-${direction}`}>
            <div className="filter-chip-header">
              <span className="chip-label">
                {label}
              </span>
              <Button
                id={`${idFormatter(label)}-filter-close-chip`}
                className="chip-close-icon"
                onClick={(e) => handleSetExpand(false, e)}
                buttonType="icon"
                aria-label="close"
              >
                <FontIcon>close</FontIcon>
              </Button>
            </div>
            <div className="filter-chip-dropdown">
              <TextField
                id={`${idFormatter(label)}-search-input`}
                className="filter-chip-search-input"
                placeholder="What are you looking for...?"
                theme="underline"
                label="Search"
                underlineDirection="left"
                rightChildren={<FontIcon>search</FontIcon>}
                onChange={(e) => setSearchTerm(e.currentTarget.value)}
              />
              <br />
              {!_.isEmpty(filteredList) &&
                <Checkbox
                  id={`${idFormatter(label)}-top-control-select`}
                  className="ph-checkbox top-control"
                  label="Select all"
                  name="select-all"
                  onChange={(e) => handleOnChangeSelectAll(e.currentTarget.checked)}
                  checked={isAllSelected()}
                  iconAfter
                />
              }
              <div className="list-container">
                <ul className="chip-list">
                  {filteredList?.map((item, index) => (
                    <li
                      title={item?.[labelKey]}
                      key={`${item?.[labelKey]}-${index}`}
                    >
                      <Checkbox
                        id={`${idFormatter(label)}-check-input-${index}`}
                        className="ph-checkbox"
                        name={`${idFormatter(label)}-check-input-${index}`}
                        label={truncate(item?.[labelKey], 42)}
                        title={item?.[labelKey]}
                        onChange={(e) => handleOnChangeCheckbox(e.currentTarget.checked, item)}
                        checked={item?.isChecked}
                        iconAfter
                      />
                    </li>
                  ))}
                </ul>
              </div>
              <br />
              <div className="filter-chip-action-container">
                <Button
                  disabled={filteredList.length === 0}
                  id={`${idFormatter(label)}-filter-apply`}
                  className="filter-chip-apply-button"
                  themeType="contained"
                  theme="primary"
                  onClick={(e) => handleApply(false, e)}
                >
                  Apply
                </Button>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

FilterChip.propTypes = {
  direction: PropTypes.string, // open direction
  filterKey: PropTypes.string, // name of parent filter state property
  label: PropTypes.string.isRequired, //  Title displayed in filter
  labelKey: PropTypes.string, // property key to display the item name, sends (value, filterKey), defaults to 'name'
  onApply: PropTypes.func, // method to pass values to parent sends (value, filterKey)
  onRemove: PropTypes.func //optional function executed after onApply if remove button is clicked
};

FilterChip.defaultProps = {
  direction: 'right',
  labelKey: 'name'
};
