import _ from 'lodash';

export * from './authentication';
export * from './fuseOptions';

export function isNotEmpty(array) {
  return !_.isEmpty(array);
}

export function orderByKey(list, key, direction = 'asc') {
  return _.orderBy(list, [o => String(o[key])?.toLowerCase()], direction);
}

export function truncate(string, max) {
  return string?.length > max ? string?.substring(0, max) + '...' : string;
}

export const idFormatter = (string) => string?.toLowerCase().split(' ').join('-');

export function createFilterList(data, labelName = 'name', valueName = 'id') {
  return _.uniqBy(data.map((item) => ({ name: item[labelName] || '(Unknown)', id: item[valueName], isChecked: true })), 'id');
}

export function TreeConstructor(unstructured) {
  let finalForm = {};
  let leaf = '';
  if (unstructured.name && unstructured.children && unstructured.children.length > 0) {
    finalForm[unstructured.name] = unstructured.children.map(child => {
      return TreeConstructor(child);
    });

  } else if (unstructured.name) {
    leaf = unstructured.name;
  }

  return finalForm[unstructured.name] ? finalForm : leaf;
};

export function flatten() {
  const flat = [];

  for (let i = 0; i < arguments.length; i++) {
    if (arguments[i] instanceof Array) {
      flat.push(...this.flatten(...arguments[i]));

    } else if (arguments[i] instanceof Object) {
      flat.push(...this.flatten(...Object.values(arguments[i])));

    } else {
      flat.push(arguments[i]);
    }
  }

  return flat;
}

export function formatArrayCells(array) {
  return (array.length <= 2 ?
    array.join(', ') :
    <span>
      {
        array.slice(0, 2).join(', ') +
        `, & ${array.length - 2} ${array.length - 2 <= 1 ? 'other' : 'others'}`
      }
    </span>
  );
}
