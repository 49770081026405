import { useState, useEffect, useCallback } from 'react';
import { AppBar, AppBarNav, AppBarTitle, Button, ArrowBackSVGIcon, FontIcon, TextIconSpacing } from 'react-md';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import { ProgressBar } from '../../';
import { cloneDeep, difference } from 'lodash';
import { isBefore } from 'date-fns';
import { getSpecifiedRelationships } from '../../../redux/slices/organizations.slice';
import { errorDialogToggle } from '../../../redux/slices/errorDialog.slice';
import { isNotEmpty } from '../../../utils';
import { PARENT_KEY } from '../../../constants/orgKeys';

export default function OrgRelationshipTypes({
  actions,
  data,
  editData,
  nextStep,
  hideStepWizard,
  title,
  options,
  previousStep,
  currentStep
}) {
  const dispatch = useDispatch();
  const { confirmOrg, update } = actions;
  const [relationshipType, setRelationshipType] = useState('');
  const [organizations, setOrganizations] = useState(null);
  const [multiOrgs, setMultiOrgs] = useState([{ relationshipType: '', organization: null, index: 0 }]);
  const [currentOrgKey, setCurrentOrgKey] = useState('');
  const [deleted, setDeleted] = useState([]);
  const [originalRelationshipType, setOriginalRelationshipType] = useState('');
  const [originalRelationships, setOriginalRelationships] = useState(null);
  const updateOrganizationError = useSelector(state => state.organizations.isError);
  const hasNoOrganizations = editData?.organization?.length < 1;
  const hasMultiOrgs = multiOrgs.length > 1;
  const externalKey = data?.childKey || editData?.autoCompleteKey || null;
  const deactivated = editData?.deactivation ? isBefore(new Date(editData?.deactivated), new Date()) : null;

  const fetchSpecifiedRelationships = useCallback(async () => {
    try {
      const specifiedRelationshipsResults = await dispatch(getSpecifiedRelationships(externalKey));
      setOriginalRelationships(specifiedRelationshipsResults?.relationships);

      return specifiedRelationshipsResults;

    } catch (error) {
      console.error('Error fetching specified relationships: ', error);
    }
  }, [externalKey, dispatch]);

  useEffect(() => {
    if (!data?.childKey && editData?.relationships) {
      const parentOrgs = editData.relationships.filter(({ hierarchy }) => hierarchy === 'parent')
        .map((parentOrg, index) => {
          return {
            relationshipType: {
              label: parentOrg?.relationshipType?.split('_')?.join(' ')?.toLowerCase(),
              value: parentOrg?.relationshipType
            },
            organization: {
              value: parentOrg?.externalKey,
              label: parentOrg?.displayName
            },
            index
          };
        });
      setMultiOrgs([...parentOrgs]);
    }
  }, [data?.childKey, editData?.relationships]);

  useEffect(() => {
    if (externalKey && !deactivated) {
      fetchSpecifiedRelationships()
        .then(results => {
          const role = { value: data?.relationshipType, label: data?.relationshipType?.split('_')?.join(' ')?.toLowerCase() };
          // relationships array is narrowed down. to the relevant relationship(s) and then formatted.
          let orgs = results?.relationships
            ?.filter(org => (org.relationshipType === role.value && org.hierarchy === 'parent'))
            ?.map(org => ({ value: org.externalKey, key: org.externalKey, label: org.displayName }));

          setRelationshipType(role);
          setOriginalRelationshipType(role);

          if (orgs) {
            setOrganizations([...orgs]);
          }

          setCurrentOrgKey(externalKey);
        }).catch((error) => {
          console.error('error: ', error);

          dispatch(errorDialogToggle({
            error: 'Error fetching relationships',
            visible: true
          }));
        });
    }
  }, [externalKey, dispatch, fetchSpecifiedRelationships, data?.relationshipType, deactivated]);

  const handleRoleChange = (role) => {
    setRelationshipType(role);
    update({ newRelationshipType: role.value });
  };

  const handleOrgChange = (list = []) => {
    const orgLength = organizations ? organizations.length : 0;
    const orgNames = organizations ? organizations.map(org => org.label) : [];
    const dataNames = list ? list.map(item => item.label) : [];

    if (orgLength > list?.length) {
      const deletedValue = [...deleted, ...difference(orgNames, dataNames)];

      setDeleted(deletedValue);
      update({ deleted: deletedValue });
    }

    setOrganizations(list);
    setRelationshipType(relationshipType);
    update({ organization: list });
  };

  const handleMultiOrgChange = (orgItem, type) => {
    let newList = cloneDeep(multiOrgs);
    newList[orgItem.index][type] = orgItem.e;

    setMultiOrgs(newList);
    update({ multiOrgs: newList });
  };

  const handleRemove = (index) => {
    const newList = cloneDeep(multiOrgs);

    if (index === 0) {
      newList.splice(index, 1, { relationshipType: '', organization: null, index: 0 });

    } else {
      newList.splice(index, 1);
    }

    setMultiOrgs(newList);
    update({ multiOrgs: newList });
  };

  const checkIfVenueTeam = (obj) => {
    if (obj?.multiOrgs &&
      !obj.autoCompleteKey &&
      obj.multiOrgs.filter(rel => rel.relationshipType.value === 'IN_HOUSE_STAFF' || rel.relationshipType.value === 'CONTRACTED_STAFF').length > 0) {
      return true;

    } else {
      return false;
    }
  };

  const handleSubmit = async () => {
    let relData, currentOrgData;

    if (data) {
      relData = {
        original: originalRelationshipType,
        new: relationshipType
      };

      currentOrgData = {
        newChildOrgName: editData.displayName,
        revenueShare: editData.revenueShare,
        childOrgName: data.childName,
        childOrgKey: currentOrgKey
      };
    }

    if (checkIfVenueTeam(editData)) {
      nextStep();

    } else {
      await confirmOrg(!!data, editData, {
        newData: organizations,
        relData,
        valsToDeactivate: deleted,
        currentOrgData,
        oldOrgData: originalRelationships
      });

      if (!updateOrganizationError) {
        hideStepWizard();
      }
    }
  };

  const handleAddRelationship = () => {
    const newList = [...multiOrgs];
    newList.push({ relationshipType: '', organization: '', index: newList.length });

    setMultiOrgs(newList);
    update({ multiOrgs: newList });
  };

  const checkDupes = (arr) => {
    const orgArr = arr.map(x => x.organization && x.organization.value);

    const hasDuplicates = (array) => {
      return (new Set(array)).size !== array.length;
    };

    return hasDuplicates(orgArr);
  };

  const checkEmpty = () => {
    const orgArr = multiOrgs.filter(x => x.organization === null);
    const roleArr = multiOrgs.filter(x => x.relationshipType === '');

    return isNotEmpty(orgArr) || isNotEmpty(roleArr);
  };

  const errorMessage =
    checkDupes(multiOrgs)
      ? `There are duplicate ORGANIZATIONS${checkEmpty() ? ' and empty entries' : ''}`
      : (checkEmpty() && hasMultiOrgs) && 'There are empty entries';

  const isDisabled = () =>
    (data ? !relationshipType || !organizations : checkEmpty())
    || !editData
    || checkDupes(multiOrgs)
    || hasNoOrganizations;

  const createMultipleRelationships = () => {
    return multiOrgs.map((rel, index) => (
      <div key={index} className="wizard-select-container">
        <Select
          classNamePrefix="wizard-select"
          id="person-organization-select"
          className="wizard-select"
          placeholder="Parent organization"
          defaultValue={rel.organization}
          value={rel.organization}
          options={options.orgOptions}
          onChange={(e) => handleMultiOrgChange({ e, index }, 'organization')}
        />
        <Select
          classNamePrefix="wizard-select"
          id="person-role-select"
          className="wizard-select"
          placeholder="Relationship to parent"
          isDisabled={!multiOrgs[index].organization}
          defaultValue={rel.relationshipType}
          value={rel.relationshipType}
          onChange={(e) => handleMultiOrgChange({ e, index }, 'relationshipType')}
          options={
            multiOrgs[index]?.organization?.value === PARENT_KEY ? options.parkhubOptions : options.relationshipOptions
          }
        />
        <Button
          className="remove-button"
          disabled={(index === 0 && hasMultiOrgs) || hasNoOrganizations}
          onClick={() => handleRemove(index)}
          buttonType="icon"
        >
          <FontIcon>delete</FontIcon>
        </Button>
      </div>
    ));
  };

  return (
    <>
      <AppBar fixed theme="primary">
        <AppBarNav onClick={previousStep} aria-label="Navigation">
          <ArrowBackSVGIcon />
        </AppBarNav>
        <AppBarTitle
          className="wizard-app-bar-title"
        >
          {data ? `Edit ${title.toLowerCase()}` : (editData?.autoCompleteKey) ? `Creating new relationships` : `Creating a new ${title.toLowerCase()}`}
        </AppBarTitle>
      </AppBar>
      <ProgressBar step={currentStep} />
      <div className="wrapper">
        <div className="wizard-step">
          <div className="wizard-step-header-container">
            <div className="wizard-step-header">
              Define relationship type
            </div>
            <div className="wizard-step-subheader">
              You’re creating a child organization, please select which parent organization and the respective relationship.
            </div>
          </div>
          {data ?
            <div className="wizard-select-container">
              <Select
                isMulti
                isClearable
                backspaceRemovesValue={false}
                isDisabled={!relationshipType}
                classNamePrefix="wizard-select"
                id="person-organization-select"
                className="wizard-select"
                placeholder="Parent organization"
                defaultValue={organizations}
                value={organizations}
                options={options.orgOptions.filter(x => x.value !== data.childKey)}
                onChange={handleOrgChange}
              />
              <Select
                classNamePrefix="wizard-select"
                id="person-role-select"
                className="wizard-select"
                placeholder="Relationship to parent"
                isDisabled={!organizations}
                defaultValue={data && { value: data.relationshipType, label: data.relationshipType.split('_').join(' ').toLowerCase() }}
                value={relationshipType}
                onChange={handleRoleChange}
                options={organizations?.value === PARENT_KEY ? options.parkhubOptions : options.relationshipOptions}
              />
            </div>
            :
            <div className="wizard-add-action-container">
              {createMultipleRelationships()}
              <Button
                className="add-item-button"
                onClick={handleAddRelationship}
              >
                <TextIconSpacing icon={<FontIcon>add</FontIcon>}>
                  Add Relationship
                </TextIconSpacing>
              </Button>
            </div>
          }
          <div className="wizard-error">
            {errorMessage}
            {hasNoOrganizations ? 'Organization Required' : true}
          </div>
          <div>
            <Button
              className="wizard-button no-elevate"
              disabled={isDisabled()}
              onClick={() => { return handleSubmit(); }}
              theme="primary"
              themeType="contained"
            >
              {data ? 'Confirm Edits' : editData?.autoCompleteKey ? `Create new relationships` : 'Create Organization'}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
